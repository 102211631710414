const closeIncident = {
	id: 'closeIncident',
	selectionType: 'single',
	label: 'actions.incident.closeincident',
	functionality: 'ACTION_CLOSE_INCIDENT',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries && registries[0].statusid != 2;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-closeIncident-' + model.name + '-show', row);
	}
};

const reopenIncident = {
	id: 'reopenIncident',
	selectionType: 'single',
	label: 'actions.incident.reopenincident',
	functionality: 'ACTION_REOPEN_INCIDENT',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries && registries[0].statusid != 1;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-reopenIncident-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [closeIncident, reopenIncident],
	formactions: [closeIncident, reopenIncident]
};
