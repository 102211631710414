<template>
	<div class="incidentFormHeader puiformheader">
		<v-row>
			<v-col cols="4">
				<pui-field :label="$t('header.incident.portname')" :value="getPortnameValue"></pui-field>
			</v-col>
			<v-col cols="4">
				<pui-field :label="$t('header.incident.portcallnumber')" :value="getPortcallnumberValue"></pui-field>
			</v-col>
			<v-col cols="4">
				<pui-field :label="$t('header.incident.vesselname')" :value="getVesselnameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'incidentFormHeader',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'incident'
		};
	},
	computed: {
		getPortnameValue() {
			return this.model && this.model.portname ? this.model.portname : '-';
		},
		getPortcallnumberValue() {
			return this.model && this.model.portcallnumber ? this.model.portcallnumber : '-';
		},
		getVesselnameValue() {
			return this.model && this.model.vesselname ? this.model.vesselname : '-';
		}
	}
};
</script>
