<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('actions.incident.closeincident')"
			:modelName="modelName"
			:dialogName="closeIncident"
			:onOk="onOkCloseIncident"
			:onShow="onShowCloseIncident"
			:showDialogProp="showDialogIncident"
			:widthDialog="700"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`newdetails-${modelName}`"
							v-model="model.newdetails"
							:label="$t('form.incident.newdetails')"
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`details-${modelName}`"
							v-model="model.details"
							:label="$t('form.incident.detailHistory')"
							disabled
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('actions.incident.reopenincident')"
			:modelName="modelName"
			:dialogName="reopenIncident"
			:onOk="onOkReopenIncident"
			:onShow="onShowReopenIncident"
			:showDialogProp="showDialogIncident"
			:widthDialog="700"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`newdetails-${modelName}`"
							v-model="model.newdetails"
							:label="$t('form.incident.newdetails')"
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`details-${modelName}`"
							v-model="model.details"
							:label="$t('form.incident.detailHistory')"
							disabled
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'incident-modals',
	data() {
		return {
			closeIncident: 'closeIncident',
			showDialogIncident: true,
			reopenIncident: 'reopenIncident',
			model: {},
			closedStatus: 2,
			openStatus: 1,
			newStatus: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onShowCloseIncident(modalData) {
			this.model = modalData;
		},
		onOkCloseIncident() {
			this.newStatus = this.closedStatus;
			return new Promise(async (resolve) => {
				await this.updateIncident(this.newStatus);
				resolve(true);
			});
		},
		onShowReopenIncident(modalData) {
			this.model = modalData;
		},
		onOkReopenIncident() {
			this.newStatus = this.openStatus;
			return new Promise(async (resolve) => {
				await this.updateIncident();
				resolve(true);
			});
		},
		updateIncident() {
			this.model.statusid = this.newStatus;
			this.$puiRequests.putRequest(
				'/incident/update',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogIncident = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogIncident = false;
				}
			);
		}
	}
};
</script>
